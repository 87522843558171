import { NavLink } from 'react-router-dom'
import { useState, useEffect } from 'react'

import {
  faCalendarDays,
  faChartLine,
  faGears,
  faHouse,
  faHouseChimneyMedical,
  faScrewdriverWrench,
  faSmile,
  faSterlingSign,
  faBell,
  faCircleQuestion,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useAuth from '../../hooks/useAuth'

const navClasses =
  'w-full text-white py-3 px-3 flex relative rounded-md items-center'

function Nav() {
  const { can } = useAuth()
  
  return (
    <ul>
      {can('access dashboard') ? (
        <li className="mb-2">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses + ' pointer-events-none'
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon icon={faHouse} className="mr-4 text-xl" />
            </span>
            Home
          </NavLink>
        </li>
      ) : null}

      {can('access calendar') ? (
        <li className="mb-2">
          <NavLink
            to="/calendar"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses + ' pointer-events-none'
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon icon={faCalendarDays} className="mr-4 text-xl" />
            </span>
            Calendar
          </NavLink>
        </li>
      ) : null}

      {can('access notifications') ? (
        <li className="mb-2">
          <NavLink
            to="/system-notifications"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses + ' pointer-events-none'
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon icon={faBell} className="mr-4 text-xl" />
            </span>
            System Notifications
          </NavLink>
        </li>
      ) : null}

      {can('access notifications') ? (
        <li className="mb-2">
          <NavLink
            to="/waiting-list"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses + ' pointer-events-none'
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon icon={faClipboardList} className="mr-4 text-xl" />
            </span>
            Waiting List
          </NavLink>
        </li>
      ) : null}

      {can('access notifications') ? (
        <li className="mb-2">
          <NavLink
            to="/query-log"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses + ' pointer-events-none'
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon icon={faCircleQuestion} className="mr-4 text-xl" />
            </span>
            Query Log
          </NavLink>
        </li>
      ) : null}

      {can('access clinics') ? (
        <li className="mb-2">
          <NavLink
            to="/clinics"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon
                icon={faHouseChimneyMedical}
                className="mr-4 text-xl"
              />
            </span>
            Clinics
          </NavLink>
        </li>
      ) : null}

      {can('access clients') ? (
        <li className="mb-2">
          <NavLink
            to="/clients"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon icon={faSmile} className="mr-4 text-xl" />
            </span>
            Clients
          </NavLink>
        </li>
      ) : null}

      {can('access accounts') ? (
        <li className="mb-2">
          <NavLink
            to="/accounts"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon icon={faSterlingSign} className="mr-4 text-xl" />
            </span>
            Accounts
          </NavLink>
        </li>
      ) : null}

      {can('access reports') ? (
        <li className="mb-2">
          <NavLink
            to="/reports"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon icon={faChartLine} className="mr-4 text-xl" />
            </span>
            Reports
          </NavLink>
        </li>
      ) : null}

      {can('access settings') ? (
        <li className="mb-2">
          <NavLink
            to="/settings"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon icon={faGears} className="mr-4 text-xl" />
            </span>
            Settings
          </NavLink>
        </li>
      ) : null}

      {can('access tools') ? (
        <li className="mb-2">
          <NavLink
            to="/tools"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon
                icon={faScrewdriverWrench}
                className="mr-4 text-xl"
              />
            </span>
            Tools
          </NavLink>
        </li>
      ) : null}
    </ul>
  )
}

export default Nav
