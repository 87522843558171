/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import {
  Link,
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import MainTitle from '../../components/MainTitle'
import ClientNav from '../../components/ClientNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faSortDown,
  faSortUp,
  faSort,
} from '@fortawesome/free-solid-svg-icons'
import Modal from '../../components/Modal'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import moment from 'moment'
import Popout from '../../components/Popout'

const Insurer = () => {
  //Auth
  const { id } = useParams()
  const { auth, setShowLoader, can, createInfo } = useAuth()
  const location = useLocation()
  const axios = useAxiosPrivate()
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [currentRecords, setCurrentRecords] = useState([])

  const [showPopout, setShowPopout] = useState(false)
  const [popoutTitle, setPopoutTitle] = useState('')

  const [addCoverFlag, setAddCoverFlag] = useState(false)
  const [editCover, setEditCover] = useState({})
  const [addCover, setAddCover] = useState({})
  const [editCoverFlag, setEditCoverFlag] = useState(false)
  const [editSession, setEditSession] = useState({})
  const [editSessionFlag, setEditSessionFlag] = useState(false)
  const [insurers, setInsurers] = useState([])
  const [covers, setCovers] = useState([])
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'WBA-1000',
  })
  const [referrableServices, setReferrableServices] = useState([])
  const [selectedReferrableServices, setSelectedReferrableServices] = useState(
    []
  )

  const [claims, setClaims] = useState([])
  const [sessionHistory, setSessionHistory] = useState([])

  const [originalRecords, setOriginalRecords] = useState([])
  const [columnClickCounts, setColumnClickCounts] = useState({})
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  })

  const getClientById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/${id}`)
      await setClientData(response.data.result)
      await setOriginalRecords(response.data.result)
      setShowLoader(false)
    } catch (error) {}
  }

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1
      return 0
    })
  }

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />
      } else {
        return <FontAwesomeIcon icon={faSortDown} />
      }
    }
    return <FontAwesomeIcon icon={faSort} />
  }

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts }
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1
    } else {
      newColumnClickCounts[key]++
    }

    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      })
      newColumnClickCounts[key] = 0
      setCurrentRecords(originalRecords.slice(0, recordsPerPage))
    } else {
      let direction =
        sortConfig.direction === 'ascending' ? 'descending' : 'ascending'
      setSortConfig({ key, direction })

      const sortedRecords = sortArray(originalRecords, key, direction)
      setCurrentRecords(
        sortedRecords.slice(indexOfFirstRecord, indexOfLastRecord)
      )
    }
    setColumnClickCounts(newColumnClickCounts)
  }

  const getInsuranceHistory = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/insurer-history/${id}`)
      if (response.data.result) {
        await setCovers(response.data.result)
      }
    } catch (error) {
      console.log(error)
    }
    setShowLoader(false)
  }

  const getReferrableServices = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/referrable-services/all`)
      if (response.data.result) {
        setReferrableServices(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const getInsurers = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/insurers`)
      if (response.data.result) {
        setInsurers(response.data.result)
      }
    } catch (error) {
      console.log(error)
    }
    setShowLoader(false)
  }

  const getClientClaims = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/${id}/claims`)
      if (response.data.result) {
        setClaims(response.data.result)
      }
    } catch (error) {
      console.log(error)
    }
    setShowLoader(false)
  }

  const getClientSessionHistory = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/${id}/session-history`)
      if (response.data.result) {
        setSessionHistory(response.data.result)
      }
    } catch (error) {
      console.log(error)
    }
    setShowLoader(false)
  }

  useEffect(() => {
    if (!location.state) {
      getClientById()
    } else {
      setClientData(location.state)
    }
    getInsuranceHistory()
    getInsurers()
    getClientClaims()
    getClientSessionHistory()
    getReferrableServices()
  }, [])

  const addCoverClicked = () => {
    setSelectedReferrableServices([])
    setAddCoverFlag(true)
    setPopoutTitle(`Add Cover`)
    setShowPopout(true)
  }

  const editCoverClicked = (event) => {
    setSelectedReferrableServices(event.referrable_services)
    setEditCover({
      ...event,
      policy_start_date: moment(event.policy_start_date).format('YYYY-MM-DD'),
      policy_end_date: moment(event.policy_end_date).format('YYYY-MM-DD'),
    })
    setEditCoverFlag(true)
    setPopoutTitle(`Edit Cover`)
    setShowPopout(true)
  }

  const editSessionsClick = (claim) => {
    setEditSession({
      claimId: claim.id,
      sessions: 0,
    })
    setEditSessionFlag(true)
    setModalTitle(`Add Extra Sessions for ${claim.reference}`)
    setShowModal(true)
  }

  const resetModal = () => {
    setAddCoverFlag(false)
    setAddCover({})
    setEditCoverFlag(false)
    setEditSession({})
    setEditSessionFlag(false)
    setShowModal(false)
    setShowPopout(false)
    setModalTitle('')
    setSelectedReferrableServices([])
    setPopoutTitle('')
  }

  const ModalBody = () => {
    if (addCoverFlag) {
      return <AddCoverElement />
    }

    if (editCoverFlag) {
      return <EditCoverElement />
    }

    if (editSessionFlag) {
      return <EditSessionElement />
    }
  }

  const updateReferrableServices = (service, mode) => {
    if (mode == 'add') {
      setAddCover(addCover)
    }
    if (mode == 'edit') {
      setEditCover(editCover)
    }
    let tempArr = [...selectedReferrableServices]
    let updated = false
    tempArr.forEach((selected, index) => {
      if (selected.title == service.title) {
        updated = true
        tempArr.splice(index, 1)
      }
    })
    if (!updated) {
      tempArr.push(service)
    }

    setSelectedReferrableServices(tempArr)
  }

  const PopoutBody = () => {
    if (addCoverFlag) {
      return <AddCoverElement />
    }

    if (editCoverFlag) {
      return <EditCoverElement />
    }

    if (editSessionFlag) {
      return <EditSessionElement />
    }
  }

  //Add Cover Modal
  const AddCoverElement = () => {
    const createCover = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      const services = []
      selectedReferrableServices.forEach((service) => {
        services.push(service.id)
      })
      try {
        const data = {
          insurer: addCover.insurer,
          policy_ref: addCover.policy_ref,
          school_name: addCover.school_name,
          school_postcode: addCover.school_postcode,
          policy_start_date: addCover.policy_start_date,
          policy_end_date: addCover.policy_end_date + ' 23:59:59',
          services,
        }
        const response = await axios.post(`/insurer-history/create/${id}`, data)
        createInfo('info', `Created Insurer`)
        getInsuranceHistory()
        resetModal()
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={createCover}>
        <div className="flex space-x-4">
          <div className="mb-3 w-1/2">
            <label
              htmlFor="email"
              className="block font-medium text-gray-600 text-sm"
            >
              School Name <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                required
                name="school_name"
                id="school_name"
                defaultValue={addCover.school_name}
                onChange={(e) => (addCover.school_name = e.target.value)}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          <div className="mb-3 w-1/2">
            <label
              htmlFor="email"
              className="block font-medium text-gray-600 text-sm"
            >
              School Postcode <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                required
                name="school_postcode"
                id="school_postcode"
                defaultValue={addCover.school_postcode}
                onChange={(e) => (addCover.school_postcode = e.target.value)}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
        </div>

        <div className="flex space-x-4">
          <div className="mb-3 w-1/2">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Insurer <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <select
                defaultValue={addCover.insurer ?? ''}
                onChange={(e) => (addCover.insurer = e.target.value)}
                name="insurer"
                id="insurer"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              >
                <option disabled value={''}>
                  Select an Option
                </option>
                {insurers?.map((option, index) => {
                  return (
                    <option className="my-2" key={index} value={option.id}>
                      {option.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className="mb-3 w-1/2">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Policy Reference <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                name="policy_ref"
                id="policy_ref"
                defaultValue={addCover.policy_ref}
                onChange={(e) => (addCover.policy_ref = e.target.value)}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="email"
            className="block font-medium text-gray-600 text-sm"
          >
            Policy Start Date <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="date"
              required
              name="policy_start_date"
              id="policy_start_date"
              defaultValue={addCover.policy_start_date}
              onChange={(e) => (addCover.policy_start_date = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="email"
            className="block font-medium text-gray-600 text-sm"
          >
            Policy End Date <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="date"
              required
              name="policy_end_date"
              id="policy_end_date"
              defaultValue={addCover.policy_end_date}
              onChange={(e) => (addCover.policy_end_date = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="email"
            className="block font-medium text-gray-600 text-sm"
          >
            Referrable Services
          </label>
          <div className="mt-1 relative rounded-md">
            {referrableServices.map((service, index) => {
              return (
                <div className="items-center flex mb-2" key={index}>
                  <input
                    type="checkbox"
                    id={`service_${index}`}
                    defaultChecked={selectedReferrableServices.some(
                      (e) => e.id === service.id
                    )}
                    onChange={() => updateReferrableServices(service, 'add')}
                  />
                  <label
                    htmlFor={`service_${index}`}
                    className="ml-2 font-medium text-gray-900 dark:text-gray-300"
                  >
                    {service.title}
                  </label>
                </div>
              )
            })}
          </div>
        </div>
        <div className="text-sm">
          <span className="text-red-500">*</span> denotes required fields.
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Add Cover</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  //Edit Cover Modal
  const EditCoverElement = () => {
    const updateCover = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      const services = []
      selectedReferrableServices.forEach((service) => {
        services.push(service.id)
      })
      try {
        let insurer = ''
        if (editCover.insurer.id) {
          insurer = editCover.insurer.id
        } else {
          insurer = editCover.insurer
        }
        const data = {
          id: editCover.id,
          insurer_id: editCover.insurer_id,
          policy_ref: editCover.policy_ref,
          school_name: editCover.school_name,
          school_postcode: editCover.school_postcode,
          policy_start_date: editCover.policy_start_date,
          policy_end_date: editCover.policy_end_date + ' 23:59:59',
          services,
        }
        const response = await axios.post(`/insurer-history/update/${id}`, data)
        createInfo('info', `Updated Insurer`)
        getInsuranceHistory()
        resetModal()
      } catch (error) {}
      setShowLoader(false)
    }
    return (
      <form onSubmit={updateCover}>
        <div className="flex space-x-4">
          <div className="mb-3 w-1/2">
            <label
              htmlFor="email"
              className="block font-medium text-gray-600 text-sm"
            >
              School Name <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                required
                name="school_name"
                id="school_name"
                defaultValue={editCover.school_name}
                onChange={(e) => (editCover.school_name = e.target.value)}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          <div className="mb-3 w-1/2">
            <label
              htmlFor="email"
              className="block font-medium text-gray-600 text-sm"
            >
              School Postcode <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                required
                name="school_postcode"
                id="school_postcode"
                defaultValue={editCover.school_postcode}
                onChange={(e) => (editCover.school_postcode = e.target.value)}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
        </div>

        <div className="flex space-x-4">
          <div className="mb-3 w-1/2">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Insurer <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <select
                defaultValue={editCover.insurer_id ?? '0'}
                onChange={(e) => {editCover.insurer_id = e.target.value;}}
                name="insurer"
                id="insurer"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              >
                <option disabled value='0'>
                  Select an Option
                </option>
                {insurers?.map((option, index) => {
                  return (
                    <option className="my-2" key={index} value={option.id}>
                      {option.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className="mb-3 w-1/2">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Policy Reference <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                name="policy_ref"
                id="policy_ref"
                defaultValue={editCover.policy_ref}
                onChange={(e) => (editCover.policy_ref = e.target.value)}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="email"
            className="block font-medium text-gray-600 text-sm"
          >
            Policy Start Date <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="date"
              required
              name="policy_start_date"
              id="policy_start_date"
              defaultValue={editCover.policy_start_date}
              onChange={(e) => (editCover.policy_start_date = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="email"
            className="block font-medium text-gray-600 text-sm"
          >
            Policy End Date <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="date"
              required
              name="policy_end_date"
              id="policy_end_date"
              defaultValue={editCover.policy_end_date}
              onChange={(e) => (editCover.policy_end_date = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="email"
            className="block font-medium text-gray-600 text-sm"
          >
            Referrable Services
          </label>
          <div className="mt-1 relative rounded-md">
            {referrableServices.map((service, index) => {
              return (
                <div className="items-center flex mb-2" key={index}>
                  <input
                    type="checkbox"
                    id={`service_${index}`}
                    defaultChecked={selectedReferrableServices.some(
                      (e) => e.title === service.title
                    )}
                    onChange={() => updateReferrableServices(service, 'edit')}
                  />
                  <label
                    htmlFor={`service_${index}`}
                    className="ml-2 font-medium text-gray-900 dark:text-gray-300"
                  >
                    {service.title}
                  </label>
                </div>
              )
            })}
          </div>
        </div>
        <div className="text-sm">
          <span className="text-red-500">*</span> denotes required fields.
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const EditSessionElement = () => {
    const updateSession = async (e) => {
      e.preventDefault()

      if (!editSession.reason) {
        createInfo('error', 'Please select a reason')
        return
      }

      setShowLoader(true)
      try {
        const data = {
          id: editSession.claimId,
          sessions: editSession.sessions,
          reason: editSession.reason,
        }
        if (editSession.reason == 'Other') {
          data.reason = editSession.other
        }
        const response = await axios.post(
          `/claims/${editSession.claimId}/extra-sessions`,
          data
        )
        if (!response.data.error) {
          createInfo('info', `Session Allocation updated`)
          let tempArr = [...sessionHistory]
          tempArr.push(response.data.result.allocation)
          setSessionHistory(tempArr)
          getClientClaims()
        }
        resetModal()
      } catch (error) {
        console.log(error)
      }
      setShowLoader(false)
    }
    return (
      <form onSubmit={updateSession}>
        <div className="mb-3">
          <label
            htmlFor="sessions"
            className="block font-medium text-gray-600 text-sm"
          >
            Enter Amount Of Sessions To Add/Deduct
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editSession.sessions}
              required
              type="number"
              name="sessions"
              id="sessions"
              onChange={(e) => (editSession.sessions = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="sessions"
            className="block font-medium text-gray-600 text-sm"
          >
            Select a reason for adding/deducting sessions
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              defaultValue={editSession.reason ?? 0}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => {
                setEditSession({ ...editSession, reason: e.target.value })
              }}
            >
              <option disabled value={0}>
                Select Reason
              </option>
              <option value="Sessions added - approved extension">
                Sessions added - approved extension
              </option>
              <option value="Session(s) deducted - DNA or late cancellation">
                Session(s) deducted - DNA or late cancellation
              </option>
              <option value="New claim">New claim</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        {editSession.reason == 'Other' && (
          <div className="mb-3">
            <label
              htmlFor="other"
              className="block font-medium text-gray-600 text-sm"
            >
              Enter reason for Other
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                defaultValue={editSession.other}
                required
                type="text"
                name="other"
                id="other"
                onChange={(e) => (editSession.other = e.target.value)}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
        )}

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  return (
    <div>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      {showPopout && (
        <Popout title={popoutTitle} body={<PopoutBody />} hide={resetModal} />
      )}
      <MainTitle
        title={`Client: ${clientData.name} (${clientData.reference})`}
        client={clientData}
        backButton
        child={clientData.child}
        discharge
        waiting
      />
      <ClientNav active="insurer" client={clientData} />

      <div className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8 mt-8">
        <div className="flex items-center justify-between">
          <div className="font-medium">Cover History</div>
          <button
            onClick={() => addCoverClicked()}
            className="rounded-lg px-4 py-2 bg-pink-700 text-white text-sm font-medium"
          >
            Add Cover
          </button>
        </div>
        <div className="mt-4">
          <table className="table-main">
            <thead>
              <tr>
                <th
                  className="cursor-pointer"
                  scope="col"
                  onClick={() => handleSort('insurer')}
                >
                  Insurer {renderSortArrow('insurer')}
                </th>
                <th
                  className="cursor-pointer"
                  scope="col"
                  onClick={() => handleSort('reference')}
                >
                  Reference {renderSortArrow('reference')}
                </th>
                <th
                  className="cursor-pointer"
                  scope="col"
                  onClick={() => handleSort('policy_start_date')}
                >
                  Policy Start Date {renderSortArrow('policy_start_date')}
                </th>
                <th
                  className="cursor-pointer"
                  scope="col"
                  onClick={() => handleSort('policy_end_date')}
                >
                  Policy End Date {renderSortArrow('policy_end_date')}
                </th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody className="table-main">
              {covers.length > 0 ? (
                covers?.map((cover) => {
                  return (
                    <tr key={cover.id}>
                      <td>{cover.insurer.name}</td>
                      <td>{cover.policy_ref}</td>
                      <td>
                        {moment(cover.policy_start_date).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        {moment(cover.policy_end_date).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        <div
                          onClick={() => editCoverClicked(cover)}
                          className="flex justify-center"
                        >
                          <span className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer">
                            <FontAwesomeIcon icon={faEdit} />
                          </span>
                        </div>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <p className="no-records">No cover found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
        <div className="flex">
          <div className="font-medium">Allocated Sessions</div>
        </div>
        <div className="mt-4">
          <table className="table-main">
            <thead>
              <tr>
                <th scope="col">Claim</th>
                <th scope="col">Service</th>
                <th scope="col">Sessions Allocated</th>
                <th scope="col">Sessions Remaining</th>
                <th scope="col">Assessments Used</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody className="table-main">
              {claims.length > 0 ? (
                claims?.map((claim) => {
                  const assessments_used = claim.sessions.filter((session) => {
                    return session.type_id == 2
                  })
                  return (
                    <tr key={claim.id}>
                      <td>{claim.reference}</td>
                      <td>{claim.service.title}</td>
                      <td>{claim.session_count}</td>
                      <td>
                        {claim.session_count - claim.used_sessions.length}
                      </td>
                      <td>{assessments_used.length}</td>
                      <td>
                        <div
                          onClick={() => editSessionsClick(claim)}
                          className="flex justify-center"
                        >
                          <span className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer">
                            <FontAwesomeIcon icon={faEdit} />
                          </span>
                        </div>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={6}>
                    <p className="no-records">No claims found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
        <div className="flex">
          <div className="font-medium">Provided Sessions History</div>
        </div>
        <div className="mt-4">
          <table className="table-main">
            <thead>
              <tr>
                <th scope="col">Claim</th>
                <th scope="col">Session Adjustment</th>
                <th scope="col">Reason</th>
                <th scope="col">Created By</th>
                <th scope="col">Created At</th>
              </tr>
            </thead>
            <tbody className="table-main">
              {sessionHistory.length > 0 ? (
                sessionHistory?.map((history) => {
                  return (
                    <tr key={history.id}>
                      <td>{history.claim_reference}</td>
                      <td>{history.sessions}</td>
                      <td>{history.reason}</td>
                      <td>{history.created_by_user}</td>
                      <td>{moment(history.created_at).format('DD/MM/YYYY')}</td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <p className="no-records">No history found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Insurer
