/* eslint-disable */
import MainTitle from '../../components/MainTitle'
import { NavLink } from 'react-router-dom'
import CardButton from '../../components/CardButton'
import useAuth from '../../hooks/useAuth'
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../../components/Pagination'
import {
  faMagnifyingGlass,
  faEye,
  faPlus,
  faSort,
  faSortUp,
  faSortDown,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'
import Modal from '../../components/Modal'
import { useNavigate, useParams, Link } from 'react-router-dom'
import moment from 'moment'

function QueryLog() {
  const axios = useAxiosPrivate()
  const navigate = useNavigate()
  const { setShowLoader, showLoader, can, createInfo } = useAuth()

  const [notifications, setNotifications] = useState([])
  const [deleteQuery, setDeleteQuery] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [search, setSearch] = useState('')
  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [modalTitle, setModalTitle] = useState('')

  const [originalRecords, setOriginalRecords] = useState([])
  const [columnClickCounts, setColumnClickCounts] = useState({})
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  })

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    if (!showLoader) {
      getQueries()
    }
  }

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1
      return 0
    })
  }

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />
      } else {
        return <FontAwesomeIcon icon={faSortDown} />
      }
    }
    return <FontAwesomeIcon icon={faSort} />
  }

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts }
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1
    } else {
      newColumnClickCounts[key]++
    }

    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      })
      newColumnClickCounts[key] = 0
      setCurrentRecords(
        originalRecords.slice(indexOfFirstRecord, indexOfLastRecord)
      )
    } else {
      let direction =
        sortConfig.direction === 'ascending' ? 'descending' : 'ascending'
      setSortConfig({ key, direction })

      const sortedRecords = sortArray(originalRecords, key, direction)
      setCurrentRecords(
        sortedRecords.slice(indexOfFirstRecord, indexOfLastRecord)
      )
    }
    setColumnClickCounts(newColumnClickCounts)
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * recordsPerPage - recordsPerPage
      const indexLast = newPage * recordsPerPage

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)

      const sortedRecords = sortArray(
        originalRecords,
        sortConfig.key,
        sortConfig.direction
      )
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast))
    }
  }

  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = (newPage - 1) * recordsPerPage
      const indexLast = newPage * recordsPerPage

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)

      const sortedRecords = sortArray(
        originalRecords,
        sortConfig.key,
        sortConfig.direction
      )
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast))
    }
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)

    console.log(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let filteredNotifications = searchString.length > 0 ? notifications.filter((e) => {
        return (
          e.client_reference.toLowerCase().match(searchString) ||
          e.claim_reference.toLowerCase().match(searchString) ||
          e.user_name.toLowerCase().match(searchString) ||
          e.outcome.toLowerCase().match(searchString) ||
          e.action.toLowerCase().match(searchString)
        )
      }) : notifications;
      if (statusFilter) {
        filteredNotifications = filteredNotifications.filter(notification => notification.title.toLowerCase() === statusFilter);
      }
      setFilteredRecords(filteredNotifications);
      setCurrentRecords(filteredNotifications.slice(indexOfFirstRecord, indexOfLastRecord));
      setNumberOfPages(Math.ceil(filteredNotifications.length / recordsPerPage));
    } else {
      setFilteredRecords(notifications)
      setCurrentRecords(notifications.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(notifications.length / recordsPerPage))
    }

    setSortConfig({
      key: null,
      direction: 'descending',
    })
  }

  const getQueries = async () => {
    try {
      const response = await axios.get('/notifications/queries')
      let filteredNotifications = response.data.result;
      if (statusFilter) {
        filteredNotifications = filteredNotifications.filter(notification => notification.title.toLowerCase() === statusFilter);
      }
      setNotifications(filteredNotifications);
      setOriginalRecords(filteredNotifications);
      setFilteredRecords(filteredNotifications);
      setCurrentRecords(filteredNotifications.slice(indexOfFirstRecord, indexOfLastRecord));
      setNumberOfPages(Math.ceil(filteredNotifications.length / recordsPerPage));
    } catch (error) {}
    setShowLoader(false)
  }

  const deleteNotificationClicked = (notification) => {
    setDeleteQuery(notification)
    setModalTitle(`Mark ${notification.client_reference} as Completed?`)
    setShowModal(true)
  }

  const resetModal = (resetNotifications = true) => {
    setDeleteQuery({})
    setShowModal(false)
  }

  const ModalBody = () => {
    if (deleteQuery.id) {
      return <DeleteNotificationElement />
    }
  }

  const viewQueryClicked = (data) => {
    navigate(`/clients/${data.client_id}`)
  }
  const DeleteNotificationElement = () => {
    const deleteQueryFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        await axios.delete('/notifications/query/delete/' + deleteQuery.id)
        createInfo('success', `Completed Notification: ${deleteQuery.title}`)

        setCurrentRecords(
          currentRecords.filter((notification) => {
            return notification.id !== deleteQuery.id
          })
        )
        setNotifications(
          notifications.filter((notification) => {
            return notification.id !== deleteQuery.id
          })
        )

        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
        createInfo('error', `Could not delete: ${deleteQuery.title}`)
      }
    }

    return (
      <form onSubmit={deleteQueryFn}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to mark this query as complete?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn green mt-4 mr-4 w-1/2">Complete</button>

            <button
              type="button"
              className="btn red mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <section>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <MainTitle title="Query Log" />

      <section>
        {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
        )}
        <ul className="flex just-between flex-wrap">
          {can('create notifications') && (
            <li className="w-1/4 mb-6 mr-3">
              <NavLink to="new">
                <CardButton icon={faPlus} title="Create New Notification" />
              </NavLink>
            </li>
          )}
          <li className="rounded-md bg-white shadow h-20 w-1/4 px-4 mb-6 flex flex-col justify-center mr-3">
            <h2 className="font-bold">Total Queries</h2>
            <p className="text-3xl font-black">{notifications.length}</p>
          </li>
        </ul>
      </section>
      <div className='flex justify-between '>
        <div className="py-4">
          <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
            </div>
            <input
              type="text"
              id="table-search"
              onChange={handleSearch}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Queries..."
            />
          </div>
        </div>
      </div>
      <table className="table-main">
        <thead>
          <tr>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('client_id')}
            >
              Client Reference {renderSortArrow('client_id')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('claim_id')}
            >
              Claim Reference {renderSortArrow('claim_id')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('start_time')}
            >
              Start Time {renderSortArrow('start_time')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('action')}
            >
              Action {renderSortArrow('action')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('outcome')}
            >
              Outcome {renderSortArrow('outcome')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('reporter')}
            >
              Reporter {renderSortArrow('reporter')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('created_by')}
            >
              Created By {renderSortArrow('created_by')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('created_at')}
            >
              Created At {renderSortArrow('created_at')}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="table-main">
          {currentRecords.length > 0 ? (
            currentRecords.map((query) => {
              return (
                <tr key={query.id}>
                  <td>{query.client_reference}</td>
                  <td>{query.claim_reference}</td>
                  <td>{query.session_start_time}</td>
                  <td>{query.action}</td>
                  <td>{query.outcome}</td>
                  <td>{query.user_name}</td>
                  <td>{query.user.name}</td>
                  <td>{moment(query.created_at).format('DD/MM/YYYY')}</td>
                  <td>
                    <div className="flex justify-center space-x-2">
                      <span
                        title="View"
                        onClick={() => viewQueryClicked(query)}
                        className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 hover:bg-blue-500 cursor-pointer">
                          <FontAwesomeIcon icon={faEye} />
                      </span>
                      <span
                        title="Complete"
                        onClick={() => deleteNotificationClicked(query)}
                        className="flex justify-center items-center bg-red-400 rounded-md text-red-800 h-9 w-12 hover:bg-red-500 cursor-pointer"
                      >
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={9}>
                <p className="no-records">No notifications found</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
      />
    </section>
  )
}

export default QueryLog
