/* eslint-disable */
import MainTitle from '../../components/MainTitle'
import { NavLink } from 'react-router-dom'
import CardButton from '../../components/CardButton'
import useAuth from '../../hooks/useAuth'
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../../components/Pagination'
import {
  faMagnifyingGlass,
  faEye,
  faPlus,
  faSort,
  faSortUp,
  faSortDown,
  faUser,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import Modal from '../../components/Modal'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

function WaitingList() {
  const axios = useAxiosPrivate()
  const navigate = useNavigate()
  let totalPractitioners = 0
  const { setShowLoader, showLoader, can, createInfo } = useAuth()

  const [notifications, setNotifications] = useState([])
  const [deleteNotification, setDeleteNotification] = useState({})
  const [showModal, setShowModal] = useState(false)

  const [users, setUsers] = useState([])
  const [assignUser, setAssignUser] = useState({})
  const [selectedNotification, setSelectedNotification] = useState({})
  const [selectedUser, setSelectedUser] = useState({})

  const [search, setSearch] = useState('')

  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [activeFilter, setActiveFilter] = useState('');
  const [clientStatuses, setNotificationStatuses] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [dropdownValue, setDropdownValue] = useState('');
  const [viewNotification, setViewNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({})


  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [modalTitle, setModalTitle] = useState('')

  const [originalRecords, setOriginalRecords] = useState([])
  const [columnClickCounts, setColumnClickCounts] = useState({})
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  })

  useEffect(() => {
    init()
    // fetchNotificationStatuses();
  }, [])

  const init = async () => {
    setShowLoader(true)
    if (!showLoader) {
      getNotifications()
    }
  }

  const viewClient = (clientId, claimId = null, sessionId = null) => {

    if (sessionId) {
      navigate(`/clients/${clientId}/claims/${claimId}/sessions/${sessionId}`)
    } else {
      navigate(`/clients/${clientId}`)
    }
  }

  const getUsers = () => 
  {
    axios.get('users/all').then((response) => {
      setUsers(response.data.result);
    }).catch(error => {
      console.log('failed to get users')
    })
  }

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1
      return 0
    })
  }

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />
      } else {
        return <FontAwesomeIcon icon={faSortDown} />
      }
    }
    return <FontAwesomeIcon icon={faSort} />
  }

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts }
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1
    } else {
      newColumnClickCounts[key]++
    }

    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      })
      newColumnClickCounts[key] = 0
      setCurrentRecords(
        originalRecords.slice(indexOfFirstRecord, indexOfLastRecord)
      )
    } else {
      let direction =
        sortConfig.direction === 'ascending' ? 'descending' : 'ascending'
      setSortConfig({ key, direction })

      const sortedRecords = sortArray(originalRecords, key, direction)
      setCurrentRecords(
        sortedRecords.slice(indexOfFirstRecord, indexOfLastRecord)
      )
    }
    setColumnClickCounts(newColumnClickCounts)
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * recordsPerPage - recordsPerPage
      const indexLast = newPage * recordsPerPage

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)

      const sortedRecords = sortArray(
        originalRecords,
        sortConfig.key,
        sortConfig.direction
      )
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast))
    }
  }

  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = (newPage - 1) * recordsPerPage
      const indexLast = newPage * recordsPerPage

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)

      const sortedRecords = sortArray(
        originalRecords,
        sortConfig.key,
        sortConfig.direction
      )
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast))
    }
  }
  
  const handleStatusChange = (handle) => {
    setActiveFilter(handle)
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle;
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (activeFilter !== '') {
      let filteredNotifications = searchString.length > 0 ? notifications.filter((e) => {
        return (
          e.area.toLowerCase().match(searchString)
        )
      }) : notifications;
      if (statusFilter) {
        filteredNotifications = filteredNotifications.filter(notification => notification.title.toLowerCase() === statusFilter);
      }
      setFilteredRecords(filteredNotifications);
      setCurrentRecords(filteredNotifications.slice(indexOfFirstRecord, indexOfLastRecord));
      setNumberOfPages(Math.ceil(filteredNotifications.length / recordsPerPage));
    } else {
      setFilteredRecords(notifications)
      setCurrentRecords(notifications.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(notifications.length / recordsPerPage))
    }

    setSortConfig({
      key: null,
      direction: 'descending',
    })
  }

  const resetFilter = () => 
  {
    setActiveFilter('')
    setFilteredRecords(notifications)
    setCurrentRecords(notifications.slice(indexOfFirstRecord, indexOfLastRecord))
    setNumberOfPages(Math.ceil(notifications.length / recordsPerPage))
    setSortConfig({
      key: null,
      direction: 'descending',
    })
  }

  const fetchNotificationStatuses = async () => {
    try {
      const response = await axios.get('/notification-statuses/all');
      const statusTitles = response.data.result.map(status => status.title);
      const sortedStatuses = statusTitles.sort((a, b) => a.localeCompare(b));
      setNotificationStatuses(sortedStatuses);
    } catch (error) {
      createInfo('Error fetching notification statuses')
    }
  };

  const resetFilters = () => {
    setStatusFilter('');
    setSearch('');
    setDropdownValue('');
  };

  const isFilterApplied = () => {
    return statusFilter !== '' || search !== '';
  };

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let filteredNotifications = searchString.length > 0 ? notifications.filter((e) => {
        return (
          e.title.toLowerCase().match(searchString) ||
          e.body.toLowerCase().match(searchString) ||
          e.type.toLowerCase().match(searchString) ||
          e.area.toLowerCase().match(searchString) ||
          e.created_at.toLowerCase().match(searchString)
        )
      }) : notifications;
      if (statusFilter) {
        filteredNotifications = filteredNotifications.filter(notification => notification.title.toLowerCase() === statusFilter);
      }
      setFilteredRecords(filteredNotifications);
      setCurrentRecords(filteredNotifications.slice(indexOfFirstRecord, indexOfLastRecord));
      setNumberOfPages(Math.ceil(filteredNotifications.length / recordsPerPage));
    } else {
      setFilteredRecords(notifications)
      setCurrentRecords(notifications.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(notifications.length / recordsPerPage))
    }

    setSortConfig({
      key: null,
      direction: 'descending',
    })
  }

  useEffect(() => {
    getNotifications();
  }, [statusFilter]);

  const getNotifications = async () => {
    try {
      const response = await axios.get('/notifications/waiting-list')
      let filteredNotifications = response.data.result;
      if (statusFilter) {
        filteredNotifications = filteredNotifications.filter(notification => notification.title.toLowerCase() === statusFilter);
      }
      console.log(response.data.result)
      setNotifications(filteredNotifications);
      setOriginalRecords(filteredNotifications);
      setFilteredRecords(filteredNotifications);
      setCurrentRecords(filteredNotifications.slice(indexOfFirstRecord, indexOfLastRecord));
      setNumberOfPages(Math.ceil(filteredNotifications.length / recordsPerPage));
    } catch (error) {}
    setShowLoader(false)
  }

  const deleteNotificationClicked = (notification) => {
    setDeleteNotification(notification)
    setModalTitle(`Mark ${notification.title} as Completed?`)
    setShowModal(true)
  }

  const resetModal = (resetNotifications = true) => {
    setDeleteNotification({})
    setAssignUser({})
    setNotificationData({})
    setSelectedNotification({})
    setSelectedUser({})
    setShowModal(false)
    setViewNotification(false)
    if (resetNotifications) {
      setNotifications(notifications)
    }
  }

  const AssignUserElement = () => {
    const assignUserSubmit = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post(`/notifications/assign-user/${selectedNotification.id}` , {
          notification_id: selectedNotification.id,
          user_id: JSON.parse(selectedUser).id
        })
        createInfo('success', `Successfully Assigned User`)
        getNotifications();
        resetModal(false)
      } catch (error) {
        console.log(error)
      }

      setShowLoader(false)
    }

    return (
      <form onSubmit={assignUserSubmit}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Assignee Name
          </label>
          <select
            defaultValue={selectedUser}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => setSelectedUser(e.target.value)}
          >
            <option disabled value={{}}>
              Select User
            </option>
            {users?.map((user) => {
                return (
                  <option key={user.id} value={JSON.stringify(user)}>
                    {user.name}
                  </option>
                )
            })}
          </select>
        </div>
        <div className="flex mt-2 w-full">
        <button type="submit" className="btn mt-4 mr-4 w-1/2">Assign User</button>
          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const ModalBody = () => {
    if (deleteNotification.id) {
      return <DeleteNotificationElement />
    }

    if(viewNotification){
      return <ViewNotificationElement />
    }

    if (assignUser) {
      return <AssignUserElement />
    }
  }

  const getNotificationType = (notificationType) => 
  {
    return notificationType.slice(notificationType.indexOf('\\') + 1)
  }

  const assignUserClicked = (data) => {
    getUsers()
    setAssignUser({})
    setSelectedNotification(data)
    setModalTitle(`Assign User`)
    setShowModal(true)
  }

  const viewNotificationClicked = (data) => {
    setNotificationData(data)
    setViewNotification(true)
    setModalTitle(`Viewing ${data.meta.client_reference ?? data.meta.client_id}`)
    setShowModal(true)
  }

  const ViewNotificationElement = () => {
    return (
      <div>
        <div className="mb-3">
          <p className="space-y-1">
            <div>
              <div className='font-medium'>Title: </div>
              <span className='text-slate-600'>{notificationData.title}</span>
            </div>
            <div className='pb-4'>
              <div className='font-medium'>Description: </div>
              <span className='text-slate-600'>{notificationData.body}</span>
            </div>
            <div className='grid grid-cols-2 space-y-1'>
              {notificationData.meta &&
                Object.entries(notificationData.meta)
                  .filter(([key]) => key !== 'client_id')
                  .map(([key, value]) => (
                    <div key={key} className="col-span-1">
                      <div className='font-medium capitalize'>{key.replace(/_/g, ' ')}: </div>
                      <span className='text-slate-600'>
                        {key.includes('date') || key.includes('at') ? formatDate(value) : value}
                      </span>
                    </div>
                  )
                )
              }
              <div>
                <div className='font-medium'>Requested By: </div>
                <span className='text-slate-600'>{notificationData.created}</span>
              </div>
            </div>
          </p>
          <div className="flex mt-2 w-full">
            {notificationData.meta?.session_id ? (
              <button onClick={() => viewClient(notificationData.meta?.client_id, notificationData.meta?.claim_id, notificationData.meta?.session_id)}  className="btn mt-4 mr-4 w-1/2">
                <span>
                  View Session
                </span>
              </button>
            ) : (
              <button onClick={() => viewClient(notificationData.meta?.client_id)} className="btn mt-4 mr-4 w-1/2">
                <span>
                  View Client
                </span>
              </button>
            )}
         
            <button
              type="button"
              className="btn red mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  }

  const DeleteNotificationElement = () => {
    const deleteNotificationFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        await axios.get('/notifications/delete/' + deleteNotification.id)
        createInfo('success', `Completed Notification: ${deleteNotification.title}`)

        setCurrentRecords(
          currentRecords.filter((notification) => {
            return notification.id !== deleteNotification.id
          })
        )
        setNotifications(
          notifications.filter((notification) => {
            return notification.id !== deleteService.id
          })
        )

        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={deleteNotificationFn}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to mark this notification as completed?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn green mt-4 mr-4 w-1/2">Complete</button>

            <button
              type="button"
              className="btn red mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <section>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <MainTitle title="Waiting List" />

      <section>
        {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
        )}
        <ul className="flex just-between flex-wrap">
          {can('create notifications') && (
            <li className="w-1/4 mb-6 mr-3">
              <NavLink to="new">
                <CardButton icon={faPlus} title="Create New Notification" />
              </NavLink>
            </li>
          )}
          <li className="rounded-md bg-white shadow h-20 w-1/4 px-4 mb-6 flex flex-col justify-center mr-3">
            <h2 className="font-bold">Total Clients</h2>
            <p className="text-3xl font-black">{notifications.length}</p>
          </li>
        </ul>
      </section>
      <div className='flex justify-between '>
        <div className="py-4">
          <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
            </div>
            <input
              type="text"
              id="table-search"
              onChange={handleSearch}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search list.."
            />
          </div>
        </div>
        {/* <div className="py-4">
          <select className="px-4 py-2 border border-gray-300 rounded-md shadow-md mr-2"  value={activeFilter} onChange={(e) => {handleStatusChange(e.target.value)}}>
          <option value=''>Filter by Status</option>
            <option value="client">Client</option>
            <option value="clinic">Clinic</option>
            <option value="session">Session</option>
            <option value="accounts">Accounts</option>
          </select>
          {activeFilter !== '' && <button onClick={() => {resetFilter('')}} className="btn red mr-4">Reset Filter</button>}
        </div> */}
      </div>
      <table className="table-main">
        <thead>
          <tr>
          <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('reference')}
            >
              Client Reference {renderSortArrow('reference')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('title')}
            >
              Title {renderSortArrow('title')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('body')}
            >
              Body {renderSortArrow('body')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('assigned')}
            >
              Assigned {renderSortArrow('assigned')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('created_at')}
            >
              Created At {renderSortArrow('created_at')}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="table-main">
          {currentRecords.length > 0 ? (
            currentRecords.map((notification) => {
              return (
                <tr key={notification.id}>
                  <td>{notification.meta.client_reference}</td>
                  <td>{notification.title}</td>
                  <td>{notification.body}</td>
                  <td>{notification.assigned == null ? 'Unassigned' : notification.assigned.name}</td>
                  <td>{moment(notification.created_at).format('DD/MM/YYYY')}</td>
                  <td>
                    <div className="flex justify-center space-x-2">
                      <span
                        title="View"
                        onClick={() => viewNotificationClicked(notification)}
                        className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 hover:bg-blue-500 cursor-pointer">
                          <FontAwesomeIcon icon={faEye} />
                      </span>
                      <span
                        title="Assign User"
                        onClick={() => assignUserClicked(notification)}
                        className="flex justify-center items-center bg-orange-400 rounded-md text-orange-800 h-9 w-12  hover:bg-orange-500 cursor-pointer"
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <span
                        title="Complete"
                        onClick={() => deleteNotificationClicked(notification)}
                        className="flex justify-center items-center bg-green-400 rounded-md text-green-800 h-9 w-12 hover:bg-green-500 cursor-pointer"
                      >
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <p className="no-records">No notifications found</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
      />
    </section>
  )
}

export default WaitingList
