import { Routes, Route } from 'react-router-dom'

import Login from './pages/Login'
import TwoFa from './pages/TwoFactor'
// import Register from './pages/Register'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'

import Missing from './pages/Missing'

// Admin pages
import AdminDashboard from './pages/Admin/Dashboard'
import AdminReports from './pages/Admin/Reports'
import AdminCalendar from './pages/Admin/Calendar'
import AdminAccounts from './pages/Admin/Accounts'
import AdminClinics from './pages/Admin/Clinics'
import AdminCreateClinic from './pages/Admin/Clinics/CreateClinic'
import AdminClinic from './pages/Admin/Clinics/Clinic'
import AdminCreateClient from './pages/Clients/CreateClient'
import AdminClients from './pages/Admin/Clients'
import Client from './pages/Clients/Client'
import AdminSettings from './pages/Admin/Settings'
import ClientClaims from './pages/Clients/Claims'
import ClientMessages from './pages/Clients/Messages'
import ClientNotes from './pages/Clients/Notes'
import ClientAccountSettings from './pages/Clients/AccountSettings'
import ClientClaim from './pages/Clients/Claim'
import ClientSession from './pages/Clients/Session'
import AdminPermissions from './pages/Admin/Settings/Permissions'
import AdminUsers from './pages/Admin/Settings/Users'
import AdminTools from './pages/Admin/Tools'
import AdminUser from './pages/Admin/User'
import AdminAccountSettings from './pages/Admin/AccountSettings'
import AdminSpecialities from './pages/Admin/Specialities'
import AdminUserClients from './pages/Admin/UserClients'
import AdminNotifications from './pages/Admin/Tools/Notifications'
import AdminAccountPayslips from './pages/Admin/AccountPayslips'
import AdminCreateQuestionnaire from './pages/Admin/Clinics/CreateQuestionnaire'

// Clinic pages
import ClinicDashboard from './pages/Clinic/Dashboard'
import Calendar from './pages/Clinic/Calendar'
import Accounts from './pages/Clinic/Accounts'
import Reports from './pages/Clinic/Reports'
import MyClinic from './pages/Clinic/MyClinic'
import MyClinicAvailability from './pages/Clinic/MyClinicAvailability'
import MyClinicBookings from './pages/Clinic/MyClinicBookings'
import Clients from './pages/Clinic/Clients'

import Notifications from './pages/Notifications'
import SystemNotifications from './pages/Admin/SystemNotifications'
import WaitingList from './pages/Admin/WaitingList'

import Layout from './components/Layout'

import MainLayout from './layouts/MainLayout'
import AuthLayout from './layouts/AuthLayout'

import RequireAuth from './components/RequireAuth'
import PersistLogin from './components/PersistLogin'
import CheckLogin from './components/CheckLogin'

import useAuth from './hooks/useAuth'
import ConfirmAssessment from './pages/ConfirmAssessment'
import SharedDocuments from './pages/Clients/SharedDocuments'
import Payslips from './pages/Clinic/Payslips'
import ClinicAvailability from './pages/Clinic/Availability'
import ClinicBookings from './pages/Clinic/Bookings'
import ClinicSettings from './pages/Clinic/Settings'
import Insurer from './pages/Clients/Insurer'

import PractitionerCalendar from './components/PractitionerCalendar'
import PractitionerBookingCalendar from './components/PractitionerBookingCalendar'
import Audits from './pages/Clients/Audits'
import QueryLog from './pages/Admin/QueryLog'

function App() {
  const { admin } = useAuth()

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes */}

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route element={<MainLayout />}>
              {admin ? (
                <Route>
                  <Route path="/" element={<AdminDashboard />} />
                  <Route path="/calendar" element={<AdminCalendar />} />
                  <Route path="/clinics" element={<AdminClinics />} />
                  <Route path="/clinics/new" element={<AdminCreateClinic />} />
                  <Route
                    path="clinics/:id/questionnaire/new"
                    element={<AdminCreateQuestionnaire />}
                  />
                  <Route path="/clinics/:id" element={<AdminClinic />} />
                  <Route path="/clinics/:id/payslips" element={<Payslips />} />
                  <Route
                    path="/clinics/:id/availability"
                    element={<ClinicAvailability />}
                  />
                  <Route
                    path="/clinics/:id/bookings"
                    element={<ClinicBookings />}
                  />
                  {/* <Route
                    path="/clinics/:id/settings"
                    element={<ClinicSettings />}
                  /> */}
                  <Route path="/clients" element={<AdminClients />} />
                  <Route path="/clients/new" element={<AdminCreateClient />} />
                  <Route path="/clients/:id" element={<Client />} />
                  <Route
                    path="/clients/:clientId/claims/:claimId"
                    element={<ClientClaim />}
                  />
                  <Route
                    path="/clients/:clientId/claims/:claimId/sessions/:sessionId"
                    element={<ClientSession />}
                  />
                  <Route
                    path="/clients/:id/claims"
                    element={<ClientClaims />}
                  />
                  <Route
                    path="/clients/:id/messages"
                    element={<ClientMessages />}
                  />
                  <Route path="/clients/:id/notes" element={<ClientNotes />} />
                  <Route
                    path="/clients/:id/account-settings"
                    element={<ClientAccountSettings />}
                  />
                  <Route
                    path="/clients/:id/shared-documents"
                    element={<SharedDocuments />}
                  />
                  <Route
                    path="/clients/:id/audits/:audit/:type"
                    element={<Audits />}
                  />
                  <Route path="/clients/:id/insurer" element={<Insurer />} />
                  <Route
                    path="/accounts/payslips"
                    element={<AdminAccountPayslips />}
                  />
                  <Route path="/accounts" element={<AdminAccounts />} />

                  <Route path="/reports" element={<AdminReports />} />
                  <Route path="/settings" element={<AdminSettings />} />

                  <Route
                    path="/settings/permissions"
                    element={<AdminPermissions />}
                  />
                  <Route path="/settings/users" element={<AdminUsers />} />
                  <Route path="/users/:id" element={<AdminUser />} />
                  <Route
                    path="/users/:id/account-settings"
                    element={<AdminAccountSettings />}
                  />
                  <Route
                    path="/users/:id/clients"
                    element={<AdminUserClients />}
                  />
                  <Route
                    path="/users/:id/specialities"
                    element={<AdminSpecialities />}
                  />

                  <Route
                    path="/users/:id/bookings"
                    element={<PractitionerBookingCalendar />}
                  />

                  <Route
                    path="/users/:id/availability"
                    element={<PractitionerCalendar />}
                  />

                  <Route path="/tools" element={<AdminTools />} />
                  <Route
                    path="/tools/notifications"
                    element={<AdminNotifications />}
                  />
                </Route>
              ) : (
                <Route>
                  <Route path="/" element={<ClinicDashboard />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/clinic" element={<MyClinic />} />
                  <Route
                    path="/clinic/availability"
                    element={<MyClinicAvailability />}
                  />
                  <Route
                    path="/clinic/bookings"
                    element={<MyClinicBookings />}
                  />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/clients/:id" element={<Client />} />
                  <Route
                    path="/clients/:clientId/claims/:claimId"
                    element={<ClientClaim />}
                  />
                  <Route
                    path="/clients/:clientId/claims/:claimId/sessions/:sessionId"
                    element={<ClientSession />}
                  />
                  <Route
                    path="/clients/:id/claims"
                    element={<ClientClaims />}
                  />
                  <Route
                    path="/clients/:id/messages"
                    element={<ClientMessages />}
                  />
                  <Route path="/clients/:id/notes" element={<ClientNotes />} />
                  <Route
                    path="/clients/:id/account-settings"
                    element={<ClientAccountSettings />}
                  />

                  <Route
                    path="/clients/:id/shared-documents"
                    element={<SharedDocuments />}
                  />
                  <Route path="/clients/:id/insurer" element={<Insurer />} />
                  <Route path="/accounts" element={<Accounts />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/users/:id" element={<AdminUser />} />
                  <Route
                    path="/users/:id/account-settings"
                    element={<AdminAccountSettings />}
                  />
                  <Route
                    path="/users/:id/clients"
                    element={<AdminUserClients />}
                  />
                  <Route
                    path="/users/:id/specialities"
                    element={<AdminSpecialities />}
                  />
                  <Route
                    path="/users/:id/bookings"
                    element={<PractitionerBookingCalendar />}
                  />
                  <Route
                    path="/users/:id/availability"
                    element={<PractitionerCalendar />}
                  />
                </Route>
              )}
              <Route path="/notifications" element={<Notifications />} />
              <Route
                path="/system-notifications"
                element={<SystemNotifications />}
              />
              <Route
                path="/waiting-list"
                element={<WaitingList />}
              />
              <Route
                path="/query-log"
                element={<QueryLog />}
              />
            </Route>

            {/* Page without using main layout */}
            {/* <Route path='/appointments/new' element={<NewAppointment />} /> */}
            <Route path="*" element={<Missing />} />
          </Route>

          <Route element={<CheckLogin />}>
            <Route element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="2fa" element={<TwoFa />} />
              {/* <Route path='register' element={<Register />} /> */}
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Route>

          {/* Catch All */}
          <Route
            path="confirm-assessment/:token"
            element={<ConfirmAssessment />}
          />
          <Route path="*" element={<Missing />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App
