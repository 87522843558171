/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'

import moment from 'moment'
import ClientNav from '../../components/ClientNav'

import MainTitle from '../../components/MainTitle'
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom'
import Modal from '../../components/Modal'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AccountSettings = () => {
  //Auth
  const { setShowLoader, can, createInfo, auth, setAuth } = useAuth()
  const axios = useAxiosPrivate()
  const location = useLocation()
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'WBA-1000',
  })

  // risk
  const [clientRisk, setClientRisk] = useState([])
  const [addAssignClinic, setAddAssignClinic] = useState(false)
  const [updateAssignClinic, setUpdateAssignClinic] = useState(false)
  const [assignedClinic, setAssignedClinic] = useState({})
  const [dischargeClinic, setDischargeClinic] = useState(false)
  const [dischargedClinic, setDischargedClinic] = useState({})
  const [upcomingSessions, setUpcomingSessions] = useState([])
  const [clinics, setClinics] = useState([])
  const [assignRisk, setAssignRisk] = useState({})
  const [riskOptions, setRiskOptions] = useState({})
  const [clinicAssignments, setClinicAssignments] = useState([])
  const { id } = useParams()

  // Modal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const errRef = useRef()
  const [errMsg, setErrMsg] = useState('')

  const getRiskByClientId = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/${id}/risk`)
      if (response.data.result) {
        await setClientRisk(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const getRiskOptions = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/risk-options/all`)
    } catch (error) {}
    setShowLoader(false)
  }

  const getClinicAssignments = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/${id}/assignments`)
      if (response.data.result) {
        await setClinicAssignments(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const getClinics = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/companies/all/with-users`)
      if (response.data.result) {
        await setClinics(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  useEffect(() => {
    setClientData(location.state)
  }, [])

  useEffect(() => {
    if (clientData.id) {
      getRiskByClientId()
      getRiskOptions()
      getClinicAssignments()
      getClinics()
      getClientSessions()
    }
  }, [clientData])

  const getClientSessions = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(
        `/clients/${clientData.id}/upcoming-sessions`
      )
      if (response.data.result) {
        await setUpcomingSessions(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const CreateClinicAssignmentElement = () => {
    const addAssignment = async (e) => {
      e.preventDefault()
      if (!assignedClinic.user_id) {
        createInfo('error', 'No new Practitioner selected')
        return
      }
      setShowLoader(true)
      try {
        const response = await axios.post(
          `/clients/${clientData.id}/assignment/add`,
          {
            clinic_id: assignedClinic.clinic.id,
            user_id: assignedClinic.user_id,
          }
        )
        getClinicAssignments()
        createInfo(
          'success',
          `Assigned Client to ${assignedClinic.clinic.name}`
        )
        resetModal()
      } catch (error) {
        console.log('test')
      }
      setShowLoader(false)
    }

    const hasCurrentPractitioner = () => {
      if (assignedClinic.clinic?.id) {
        const assignee = clinicAssignments.forEach((assignment) => {
          if (assignedClinic.clinic.id == assignment[0].id) {
            return assignment[1].name
          }
        })
      }
      return ''
    }

    return (
      <form onSubmit={addAssignment}>
        {!assignedClinic.clinic?.id && (
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Clinic
            </label>
            <div className="mt-1 relative rounded-md">
              <select
                defaultValue={JSON.stringify(assignedClinic.clinic) ?? {}}
                className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                required
                onChange={(e) => {
                  setAssignedClinic({
                    ...assignedClinic,
                    clinic: JSON.parse(e.target.value),
                  })
                  setModalTitle(
                    `Assign to Clinic - ${JSON.parse(e.target.value).name}`
                  )
                }}
                id="clinic"
              >
                <option disabled value={{}}>
                  Select a Clinic
                </option>
                {clinics?.map((option) => {
                  let show = true
                  clinicAssignments.forEach((assignment) => {
                    if (assignment[0].id == option.id) {
                      show = false
                    }
                  })
                  if (show) {
                    return (
                      <option key={option.id} value={JSON.stringify(option)}>
                        {option.name}
                      </option>
                    )
                  }
                })}
              </select>
            </div>
          </div>
        )}

        {hasCurrentPractitioner() !== '' && (
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Current Practitioner
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                id={hasCurrentPractitioner()}
                readOnly
                type="text"
                value={hasCurrentPractitioner()}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md pointer-events-none bg-slate-200"
              />
            </div>
          </div>
        )}

        {assignedClinic.clinic?.id && (
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Assign a new Practitioner
            </label>
            <div className="mt-1 relative rounded-md">
              <select
                defaultValue={assignedClinic.user_id ?? ''}
                className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                onChange={(e) => {
                  setAssignedClinic({
                    ...assignedClinic,
                    user_id: e.target.value,
                  })
                }}
                id="clinic"
              >
                <option disabled value={''}>
                  Select a Practitioner
                </option>
                {assignedClinic.clinic?.users?.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        )}

        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm mb-2"
          >
            Upcoming Client Sessions
          </label>
          <table className="table-main">
            <thead>
              <tr>
                <th scope="col">Session Type</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {upcomingSessions.length > 0 ? (
                upcomingSessions?.map((session) => {
                  if (!assignedClinic.clinic?.id) {
                    return (
                      <tr key={session.id}>
                        <td>
                          {session.type_id === 2 ? 'Assessment' : 'Appointment'}
                        </td>
                        <td>
                          {moment(session.start_time).format(
                            'DD/MM/YYYY H:m:s'
                          )}
                        </td>
                      </tr>
                    )
                  } else {
                    if (session.company_id == assignedClinic.clinic?.id) {
                      return (
                        <tr key={session.id}>
                          <td>
                            {session.type_id === 2
                              ? 'Assessment'
                              : 'Appointment'}
                          </td>
                          <td>
                            {moment(session.start_time).format(
                              'DD/MM/YYYY H:m:s'
                            )}
                          </td>
                        </tr>
                      )
                    }
                  }
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <p className="no-records">No Upcoming Client Sessions</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Assign</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const UpdateClinicAssignmentElement = () => {
    const updateAssignment = async (e) => {
      e.preventDefault()
      if (!assignedClinic.user_id) {
        createInfo('error', 'No new Practitioner selected')
        return
      }
      setShowLoader(true)
      try {
        const response = await axios.post(
          `/clients/${clientData.id}/assignment/add`,
          {
            clinic_id: assignedClinic.clinic.id,
            user_id: assignedClinic.user_id,
          }
        )
        getClinicAssignments()
        createInfo('success', `Client has been re-assigned`)
        resetModal()
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={updateAssignment}>
        {!assignedClinic.clinic?.id && (
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Clinic
            </label>
            <div className="mt-1 relative rounded-md">
              <select
                defaultValue={JSON.stringify(assignedClinic.clinic) ?? {}}
                className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                onChange={(e) => {
                  setAssignedClinic({
                    ...assignedClinic,
                    clinic: JSON.parse(e.target.value),
                  })
                  setModalTitle(
                    `Assign to Clinic - ${JSON.parse(e.target.value).name}`
                  )
                }}
                id="clinic"
              >
                <option disabled value={{}}>
                  Select a Clinic
                </option>
                {clinics?.map((option) => {
                  let show = true
                  clinicAssignments.forEach((assignment) => {
                    if (assignment[0].id == option.id) {
                      show = false
                    }
                  })
                  if (show) {
                    return (
                      <option key={option.id} value={JSON.stringify(option)}>
                        {option.name}
                      </option>
                    )
                  }
                })}
              </select>
            </div>
          </div>
        )}

        {assignedClinic.user?.id && (
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Current Practitioner
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                id={assignedClinic.user.id}
                readOnly
                type="text"
                value={assignedClinic.user.name}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md pointer-events-none bg-slate-200"
              />
            </div>
          </div>
        )}

        {assignedClinic.clinic?.id && (
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Assign a new Practitioner
            </label>
            <div className="mt-1 relative rounded-md">
              <select
                defaultValue={assignedClinic.user_id ?? ''}
                className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                onChange={(e) => {
                  setAssignedClinic({
                    ...assignedClinic,
                    user_id: e.target.value,
                  })
                }}
                id="clinic"
              >
                <option disabled value={''}>
                  Select a Practitioner
                </option>
                {assignedClinic.clinic?.users?.map((user) => {
                  if (user.id === assignedClinic.user?.id) {
                    return null
                  }
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        )}

        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm mb-2"
          >
            Upcoming Client Sessions
          </label>
          <table className="table-main">
            <thead>
              <tr>
                <th scope="col">Session Type</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {upcomingSessions.length > 0 ? (
                upcomingSessions?.map((session) => {
                  if (!assignedClinic.clinic?.id) {
                    return (
                      <tr key={session.id}>
                        <td>
                          {session.type_id === 2 ? 'Assessment' : 'Appointment'}
                        </td>
                        <td>
                          {moment(session.start_time).format(
                            'DD/MM/YYYY H:m:s'
                          )}
                        </td>
                      </tr>
                    )
                  } else {
                    if (session.company_id == assignedClinic.clinic?.id) {
                      return (
                        <tr key={session.id}>
                          <td>
                            {session.type_id === 2
                              ? 'Assessment'
                              : 'Appointment'}
                          </td>
                          <td>
                            {moment(session.start_time).format(
                              'DD/MM/YYYY H:m:s'
                            )}
                          </td>
                        </tr>
                      )
                    }
                  }
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <p className="no-records">No Upcoming Client Sessions</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <p className="text-rose-500 text-sm font-bold text-center">
          To unnasign the client from this clinic. Please use the 'discharge'
          button
        </p>

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Re-assign</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const assignClinicClicked = () => {
    setAddAssignClinic(true)
    setModalTitle(`Assign to Clinic`)
    setShowModal(true)
  }

  const updateAssignClinicClicked = (clinic, user) => {
    setUpdateAssignClinic(true)
    setAssignedClinic({ clinic, user })
    setModalTitle(`Re-assign Clinic ${clinic.name}`)
    setShowModal(true)
  }

  const dischargeClinicClicked = (clinic) => {
    setDischargeClinic(true)
    setDischargedClinic(clinic)
    setModalTitle(`Discharge ${clientData.name} from ${clinic.name}`)
    setShowModal(true)
  }

  const DischargeClinicElement = () => {
    const discharge = async (e) => {
      setShowModal(false)
      setShowLoader(true)
      await axios.get(
        `/clients/${clientData.id}/discharge/${dischargedClinic.id}`
      )
      createInfo('error', `Client Discharged from ${dischargedClinic.name}`)
      setShowLoader(false)
      getClinicAssignments()
    }
    return (
      <form onSubmit={discharge} className="justify-center flex w-full">
        <div className="mb-3">
          <p className="text-lg font-bold mt-8 text-center">
            This will remove the client until they are reassigned.
          </p>
          <p className="text-lg font-bold mt-4 mb-8 text-center">
            Any upcoming sessions will be cancelled.
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Discharge</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const ModalBody = () => {
    // if (assignRisk) {
    //   return <CreateRiskAssignElement />
    // }

    if (addAssignClinic) {
      return <CreateClinicAssignmentElement />
    }

    if (updateAssignClinic) {
      return <UpdateClinicAssignmentElement />
    }

    if (dischargeClinic) {
      return <DischargeClinicElement />
    }
  }

  const resetModal = () => {
    setAssignedClinic({})
    setAddAssignClinic(false)
    setDischargeClinic(false)
    setDischargedClinic({})
    setUpdateAssignClinic(false)
    setShowModal(false)
  }

  return (
    <section>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <MainTitle
        title={`Client: ${clientData.name} (${clientData.reference})`}
        client={clientData}
        backButton
        child={clientData.child}
        discharge
        waiting
      />

      <ClientNav active="account-settings" client={clientData} />

      <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
        <div className="flex items-center mb-4">
          <h2 className="font-bold text-lg ">Clinic Assignment</h2>

          {can('add clinic assignment') && (
            <span
              title="Remove Assignment"
              className="py-1.5 px-6 ml-8 inline-flex text-sm justify-center items-center rounded-md bg-emerald-400 cursor-pointer hover:bg-emerald-500 text-white"
              onClick={() => assignClinicClicked()}
            >
              Add New
            </span>
          )}
        </div>
        <div className="flex">
          <ul className="flex w-full bg-slate-100 py-2 rounded-md min-h-[35px]">
            {clinicAssignments?.map((assignment) => {
              return (
                <li key={assignment[0].id} className="flex">
                  <span
                    onClick={() =>
                      updateAssignClinicClicked(assignment[0], assignment[1])
                    }
                    className="pill small slate hover:bg-slate-500"
                    style={{
                      fontSize: '15px',
                      padding: '10px 20px',
                      cursor: 'pointer',
                    }}
                  >
                    {assignment[0].name}
                  </span>
                  {can('remove clinic assignments') ? (
                    <span
                      onClick={() => dischargeClinicClicked(assignment[0])}
                      title="Discharge"
                      className="pill-button small"
                      style={{ padding: '0 10px' }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  ) : (
                    <span className="mr-4"></span>
                  )}
                </li>
              )
            })}
          </ul>
        </div>

        <p className="mb-4 mt-2 text-sm text-blue-500">
          Note: All upcoming sessions for the related clinic will be re-assignd
          if a new practitioner is selected. If a new session is booked with a
          different practitioner, the assignment will change to the newly
          booked-in practitioner
        </p>

        <hr className="my-10" />

        <h2 className="font-bold text-lg mb-4">Risk Level History</h2>
        <div className="flex">
          <div className="w-1/3 mr-4">
            <div className="my-4">
              <span className="text-wba-primary font-semibold mr-2">
                Current Risk Level:
              </span>
              <span
                className="status"
                style={{
                  backgroundColor: clientRisk[0]?.color
                    ? clientRisk[0]?.color
                    : 'rgb(12, 210, 32)',
                }}
              >
                {clientRisk[0]?.title ?? 'No Risk'}
              </span>
            </div>
            <table className="table-main">
              <thead>
                <tr>
                  <th>Risk Level</th>
                  <th>Risk Reason</th>
                  <th>Created</th>
                  <th>Updated By</th>
                </tr>
              </thead>
              <tbody>
                {clientRisk.length > 0 ? (
                  clientRisk?.map((level, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span
                            className="status"
                            style={{ backgroundColor: level.color }}
                          >
                            {level?.title}
                          </span>
                        </td>
                        <td>{level?.reason?.title}</td>
                        <td>
                          {moment(level?.pivot?.created_at).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </td>
                        <td>{level?.pivot?.updated_by}</td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <p className="no-records">No Risk Levels found</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      {/* {can("view roles") && (
        <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
          <div className="flex items-center mb-4 w-full justify-between">
            <h2 className="font-bold text-lg">Roles</h2>
            {can(`assign roles`) && (
              <div
                className="cursor-pointer ml-4 text-blue-500 font-bold items-center flex btn"
                onClick={assignRoleClicked}
              >
                <span>Assign Role</span>
              </div>
            )}
          </div>

          <ul className="flex">
            {assignedRoles?.map((role) => {
              return (
                <li key={role.id} className="flex">
                  <span className="pill">{role.name}</span>
                  {can("remove roles") && role.name !== "Super User" ? (
                    <span
                      title="Remove Role"
                      className="pill-button"
                      onClick={() => deleteRoleClicked(role)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  ) : (
                    <span className="mr-4"></span>
                  )}
                </li>
              )
            })}
          </ul>
        </section>
      )} */}
    </section>
  )
}

export default AccountSettings
