/* eslint-disable */
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import MainTitle from '../../components/MainTitle'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import ClientNav from '../../components/ClientNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../../components/Pagination'
import {
  faMagnifyingGlass,
  faEye,
  faUserXmark,
  faPenToSquare,
  faFolderOpen,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Modal from '../../components/Modal'

const ClientClaims = () => {
  const { id } = useParams()
  const { setShowLoader, can, createInfo, auth, admin } = useAuth()

  const location = useLocation()
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'WBA-1000',
  })
  const [claims, setClaims] = useState([])

  const [addClaim, setAddClaim] = useState(false)
  const [editClaim, setEditClaim] = useState({})
  const [dischargeClaim, setDischargeClaim] = useState(false)
  const [reopenedClaim, setReopenedClaim] = useState(false)
  const [newClaim, setNewClaim] = useState({})
  const [externalSource, setExternalSource] = useState({})
  const [selectedClinic, setSelectedClinic] = useState({})
  const [selectedService, setSelectedService] = useState({})
  const [requireAssessment, setRequireAssessment] = useState(true)

  const [clinics, setClinics] = useState([])
  const [sources, setSources] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [showTriageOptions, setShowTriageOptions] = useState(false)
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentRecords, setCurrentRecords] = useState([])
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const navigate = useNavigate()
  const axios = useAxiosPrivate()

  const getClaimsByClientId = async () => {
    let url = ''
    if (admin) {
      url = `/clients/${id}/claims`
      // if (auth.user.type_id === 3 || auth.user.type_id === 4) {
      //   url += `/by-user/${auth.user.id}/${auth.user.type_id}`
      // }
    } else {
      if (auth.user.type_id === 3 || auth.user.type_id === 4) {
        url += `/clients/${id}/claims/by-user/${auth.user.id}/${auth.user.type_id}`
      }
      // url = `/clients/company/${id}/${auth.user.id}/claims`
    }

    try {
      setShowLoader(true)
      const response = await axios.get(url)
      if (response.data.result) {
        await setClaims(response.data.result)
        await setFilteredRecords(response.data.result)
        await setCurrentRecords(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  useEffect(() => {
    setClientData(location.state)
    if (!location.state) {
      navigate(`/clients/${id}`)
    } else {
      getClaimsByClientId()
    }
  }, [])

  const dischargClaimClicked = (claim) => {
    setDischargeClaim({ ...claim })
    setModalTitle(`Update Claim: ${claim.reference}`)
    setShowModal(true)
  }

  const reopenClaimClicked = (claim) => {
    setReopenedClaim({ ...claim })
    setModalTitle(`Reopen claim: ${claim.reference}`)
    setShowModal(true)
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = claims.filter((e) => {
        return (
          e.external_reference.toLowerCase().match(searchString) ||
          e.company_relationship.name.toLowerCase().match(searchString) ||
          e.service.title.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(claims)
      setCurrentRecords(claims.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(claims.length / recordsPerPage))
    }
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  const addClaimClicked = () => {
    setAddClaim({})
    setModalTitle(`Add Claim`)
    setShowModal(true)
    getClaimsInfo()
  }

  const editClaimClicked = (claim) => {
    setEditClaim(claim)
    setModalTitle(`Edit Claim`)
    setShowModal(true)
    getClaimsInfo(claim.company_id, claim.service_id)
  }

  const ModalBody = () => {
    if (addClaim) {
      return <AddClaimElement />
    }

    if (dischargeClaim) {
      return <DischargeClaimElement />
    }

    if (reopenedClaim) {
      return <ReopenClaimElement />
    }

    if (editClaim) {
      return <EditClaimElement />
    }

    return null
  }

  const getClaimsInfo = async (clinicId = null, serviceId = null) => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/claims/info`)
      if (response.data.result) {
        await setClinics(response.data.result.clinics)
        await setSources(response.data.result.sources)
        setShowLoader(false)

        if (clinicId) {
          const clinic = response.data.result.clinics.filter((item) => {
            return item.id === clinicId
          })[0]

          setSelectedClinic(JSON.stringify(clinic))

          if (clinic) {
            const service = clinic.services.filter((item) => {
              return item.id === serviceId
            })[0]

            setSelectedService(JSON.stringify(service))

            if ([17].includes(service.id)) {
              setShowTriageOptions(true)
            } else {
              setShowTriageOptions(false)
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
      setShowLoader(false)
    }
  }

  const changeService = (service) => {
    setSelectedService(service)
    if ([17].includes(JSON.parse(service).id)) {
      setShowTriageOptions(true)
    } else {
      setShowTriageOptions(false)
    }
  }

  const AddClaimElement = () => {
    const addClaim = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const triageOptions = {
          reason: newClaim.call_reason,
          services: newClaim.other_services,
          outcomes: newClaim.outcomes,
        }

        const response = await axios.post(
          `/clients/${clientData.id}/claims/add`,
          {
            client_id: clientData.id,
            company_id: JSON.parse(selectedClinic).id,
            service_id: JSON.parse(selectedService).id,
            external_reference: newClaim.external_reference,
            external_source_id: JSON.parse(externalSource).id,
            status_id: requireAssessment ? 1 : 2,
            assessment_flag: requireAssessment,
            session_count: JSON.parse(selectedService).default_sessions,
            assessment_count: JSON.parse(selectedService).assessment,
            triage_options: triageOptions,
          }
        )
        if (response.data.error === false) {
          createInfo('success', `Claim Added: ${newClaim.external_reference}`)
          setCurrentRecords([...currentRecords, response.data.result])
          setClaims([...claims, response.data.result])
          setAddClaim(false)
          setNewClaim({})
          resetModal()
          viewClaimClicked(response.data.result)
        }
      } catch (error) {
        if (error == 'SyntaxError: "[object Object]" is not valid JSON') {
          createInfo('error', `Please fill out the required fields`)
        } else {
          createInfo('error', `Failed to create claim`)
        }
      }
      setShowLoader(false)
    }

    const handleClinicChange = (e) => {
      const selectedClinicValue = e.target.value;
      setSelectedClinic(selectedClinicValue);
      const clinic = JSON.parse(selectedClinicValue);
      const services = clinic.services_relationship.filter(
        (service) => service.status !== 'Disabled' && service.assessment !== 1
      );
  
      if (services.length === 1) {
        setSelectedService(JSON.stringify(services[0]));
      } else {
        setSelectedService({});
      }
    }

    return (
      <form onSubmit={addClaim}>
        <div className="mb-3">
          <label
            htmlFor="external_source"
            className="block font-medium text-gray-600 text-sm"
          >
            External Source <span className="text-red-500">*</span>
          </label>
          <select
            defaultValue={externalSource}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            id="external_source"
            onChange={(e) => setExternalSource(e.target.value)}
          >
            <option disabled value={{}}>
              Select Source
            </option>
            {sources?.map((source) => {
              return (
                <option key={source.id} value={JSON.stringify(source)}>
                  {source.name}
                </option>
              )
            })}
          </select>
        </div>
        <div className="mb-3">
          <label
            htmlFor="reference"
            className="block font-medium text-gray-600 text-sm"
          >
            External Reference <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="reference"
              id="reference"
              defaultValue={newClaim.external_reference}
              onChange={(e) => (newClaim.external_reference = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="clinic"
            className="block font-medium text-gray-600 text-sm"
          >
            Assign to Clinic <span className="text-red-500">*</span>
          </label>

          <select
            defaultValue={selectedClinic}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            id="clinic"
            onChange={handleClinicChange}
          >
            <option disabled value={{}}>
              Select Clinic
            </option>
            {clinics?.map((clinic) => {
              return (
                <option key={clinic.id} value={JSON.stringify(clinic)}>
                  {clinic.name}
                </option>
              )
            })}
          </select>
        </div>
        {selectedClinic.length > 3 && JSON.parse(selectedClinic)?.id && (
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Service <span className="text-red-500">*</span>
            </label>
            <select
              defaultValue={selectedService}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => changeService(e.target.value)}
            >
              <option disabled value={{}}>
                Select Service
              </option>
              {JSON.parse(selectedClinic)?.services_relationship?.map(
                (service) => {
                  if (
                    service.status !== 'Disabled' &&
                    service.assessment !== 1
                  ) {
                    return (
                      <option key={service.id} value={JSON.stringify(service)}>
                        {service.title}
                      </option>
                    );
                  }
                }
              )}
            </select>
          </div>
        )}
        <div>
          {showTriageOptions && (
            <div className="mb-3">
              <div className="mb-3">
                <label
                  htmlFor="call_reason"
                  className="block font-medium text-gray-600 text-sm"
                >
                  Reason for Call <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 relative rounded-md">
                  <textarea
                    required
                    type="text"
                    name="call_reason"
                    id="call_reason"
                    defaultValue={newClaim.call_reason}
                    onChange={(e) => (newClaim.call_reason = e.target.value)}
                    className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  ></textarea>
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="other_services"
                  className="block font-medium text-gray-600 text-sm"
                >
                  Other Services Accessed{' '}
                  <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 relative rounded-md">
                  <textarea
                    required
                    type="text"
                    name="other_services"
                    id="other_services"
                    defaultValue={newClaim.other_services}
                    onChange={(e) => (newClaim.other_services = e.target.value)}
                    className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  ></textarea>
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="outcomes"
                  className="block font-medium text-gray-600 text-sm"
                >
                  Outcomes <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 relative rounded-md">
                  <textarea
                    required
                    type="text"
                    name="outcomes"
                    id="outcomes"
                    defaultValue={newClaim.outcomes}
                    onChange={(e) => (newClaim.outcomes = e.target.value)}
                    className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  ></textarea>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mb-3 w-1/2 mr-2 flex items-center">
          <div className="relative mr-2">
            <input
              type="checkbox"
              id="clinic_details"
              onChange={(e) => {
                setRequireAssessment(e.target.checked)
              }}
              placeholder="Assessment Required?"
              className="field"
              checked={requireAssessment}
            />
          </div>
          <label
            htmlFor="clinic_details"
            className="block font-medium text-gray-600 text-sm"
          >
            Initial Assessment is required
          </label>
        </div>
        <div className="text-sm">
          <span className="text-red-500">*</span> denotes required fields.
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Add Claim</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const EditClaimElement = () => {
    const updateClaim = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      setIsSubmitting(true)
      try {
        let triageOptions = {}
        if (editClaim.triage_options) {
          triageOptions = {
            reason: editClaim.triage_options.reason ?? '',
            services: editClaim.triage_options.services ?? '',
            outcomes: editClaim.triage_options.outcomes ?? '',
          }
        }

        const response = await axios.post(`/claims/update/${editClaim.id}`, {
          company_id: JSON.parse(selectedClinic).id,
          service_id: JSON.parse(selectedService).id,
          external_reference: editClaim.external_reference,
          external_source_id: editClaim.external_source_id,
          assessment_flag: editClaim.assessment_flag,
          triage_options: triageOptions,
        })
        if (response.data.error === false) {
          createInfo(
            'success',
            `Claim Updated: ${editClaim.external_reference}`
          )
          getClaimsByClientId()
          setEditClaim(false)
          resetModal()
        }
      } catch (error) {
        console.log(error)
        if (error == 'SyntaxError: "[object Object]" is not valid JSON') {
          createInfo('error', `Please fill out the required fields`)
        } else {
          createInfo('error', `Failed to update claim`)
        }
      }
      setShowLoader(false)
      setIsSubmitting(false)
    }

    return (
      <form onSubmit={updateClaim}>
        <div className="mb-3">
          <label
            htmlFor="external_source"
            className="block font-medium text-gray-600 text-sm"
          >
            External Source <span className="text-red-500">*</span>
          </label>
          <select
            // Set the selected value to claim's external source
            defaultValue={editClaim.external_source_id}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            id="external_source"
            onChange={(e) =>
              setEditClaim({
                ...editClaim,
                external_source_id: e.target.value,
              })
            }
          >
            <option disabled value="">
              Select Source
            </option>
            {sources?.map((source) => (
              <option key={source.id} value={source.id}>
                {source.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label
            htmlFor="reference"
            className="block font-medium text-gray-600 text-sm"
          >
            External Reference <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="reference"
              id="reference"
              defaultValue={editClaim.external_reference}
              onChange={(e) => (editClaim.external_reference = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="clinic"
            className="block font-medium text-gray-600 text-sm"
          >
            Assign to Clinic <span className="text-red-500">*</span>
          </label>
          <select
            defaultValue={selectedClinic}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            id="clinic"
            onChange={(e) => {
              setSelectedClinic(e.target.value)
              setSelectedService({})
            }}
          >
            <option disabled value={{}}>
              Select Clinic
            </option>
            {clinics?.map((clinic) => {
              return (
                <option key={clinic.id} value={JSON.stringify(clinic)}>
                  {clinic.name}
                </option>
              )
            })}
          </select>
        </div>
        {selectedClinic.length > 3 && JSON.parse(selectedClinic)?.id && (
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block font-medium text-gray-600 text-sm"
            >
              Service <span className="text-red-500">*</span>
            </label>
            <select
              defaultValue={selectedService}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => changeService(e.target.value)}
            >
              <option disabled value={{}}>
                Select Service
              </option>
              {JSON.parse(selectedClinic)?.services_relationship?.map(
                (service) => {
                  if (
                    service.status !== 'Disabled' &&
                    service.assessment !== 1
                  ) {
                    return (
                      <option key={service.id} value={JSON.stringify(service)}>
                        {service.title}
                      </option>
                    )
                  }
                }
              )}
            </select>
          </div>
        )}
        <div>
          {showTriageOptions && (
            <div className="mb-3">
              <div className="mb-3">
                <label
                  htmlFor="call_reason"
                  className="block font-medium text-gray-600 text-sm"
                >
                  Reason for Call <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 relative rounded-md">
                  <textarea
                    type="text"
                    name="call_reason"
                    id="call_reason"
                    defaultValue={editClaim.triage_options?.reason ?? ''}
                    onChange={(e) =>
                      (editClaim.triage_options.reason = e.target.value)
                    }
                    className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  ></textarea>
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="other_services"
                  className="block font-medium text-gray-600 text-sm"
                >
                  Other Services Accessed{' '}
                  <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 relative rounded-md">
                  <textarea
                    type="text"
                    name="other_services"
                    id="other_services"
                    defaultValue={editClaim.triage_options?.services ?? ''}
                    onChange={(e) =>
                      (editClaim.triage_options.services = e.target.value)
                    }
                    className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  ></textarea>
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="outcomes"
                  className="block font-medium text-gray-600 text-sm"
                >
                  Outcomes <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 relative rounded-md">
                  <textarea
                    type="text"
                    name="outcomes"
                    id="outcomes"
                    defaultValue={editClaim.triage_options?.outcomes ?? ''}
                    onChange={(e) =>
                      (editClaim.triage_options.outcomes = e.target.value)
                    }
                    className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  ></textarea>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mb-3 w-1/2 mr-2 flex items-center">
          <div className="relative mr-2">
            <input
              type="checkbox"
              id="clinic_details"
              onChange={(e) => {
                setEditClaim({
                  ...editClaim,
                  assessment_flag: e.target.checked,
                })
              }}
              placeholder="Assessment Required?"
              className="field"
              checked={editClaim.assessment_flag}
            />
          </div>
          <label
            htmlFor="clinic_details"
            className="block font-medium text-gray-600 text-sm"
          >
            Initial Assessment is required
          </label>
        </div>
        <div className="text-sm">
          <span className="text-red-500">*</span> denotes required fields.
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">
            {isSubmitting ? 'Updating...' : 'Update Claim'}
          </button>
          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const DischargeClaimElement = () => {
    const dischargeClientFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        let data = {
          client_id: clientData.id,
        }
        const response = await axios.post(
          '/claims/discharge/' + dischargeClaim.id + '',
          data
        )
        createInfo('success', `Claim Updated: ${dischargeClaim.reference}`)
        setClaims(response.data.result)
        setFilteredRecords(response.data.result)
        setCurrentRecords(response.data.result)

        setNumberOfPages(
          Math.ceil(response.data.result.length / recordsPerPage)
        )
        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={dischargeClientFn}>
        <div className="mb-3">
          <div className="items-center">
            <p className="text-lg font-bold mt-8 text-center">
              Are you sure you want to discharge the client?
            </p>
            <p className="italic text-center mb-8">
              The client will be discharged from the service.
            </p>
          </div>

          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Discharge Claim</button>

            <button
              type="button"
              className="btn bg-blue-500 mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const ReopenClaimElement = () => {
    const reopenClaimFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        let data = {
          client_id: clientData.id,
        }
        const response = await axios.post(
          '/claims/reopen/' + reopenedClaim.id + '',
          data
        )
        createInfo('success', `Claim Reopened: ${reopenedClaim.reference}`)
        setClaims(response.data.result)
        setFilteredRecords(response.data.result)
        setCurrentRecords(response.data.result)

        setNumberOfPages(
          Math.ceil(response.data.result.length / recordsPerPage)
        )
        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={reopenClaimFn}>
        <div className="mb-3">
          <div className="items-center">
            <p className="text-lg font-bold mt-8 text-center">
              Are you sure you want to reopen the claim?
            </p>
          </div>

          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Reopen Claim</button>

            <button
              type="button"
              className="btn bg-blue-500 mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = () => {
    setAddClaim(false)
    setEditClaim(false)
    setDischargeClaim(false)
    setReopenedClaim(false)
    setNewClaim({})
    setSelectedClinic({})
    setExternalSource({})
    setSelectedService({})
    setShowModal(false)
    setModalTitle('')
    setShowTriageOptions(false)
  }

  const viewClaimClicked = (claim) => {
    navigate(`/clients/${id}/claims/${claim.id}`)
  }

  return (
    <div>
      <MainTitle
        title={`Client: ${clientData.name} (${clientData.reference})`}
        client={clientData}
        backButton
        child={clientData.child}
        discharge
        waiting
      />
      <ClientNav active="claims" client={clientData} />

      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      {clientData.id && (
        <section>
          <h2 className="font-bold text-lg mb-4">Claims</h2>
          {can('view client claims') && (
            <div>
              <div className="pb-4 flex justify-between">
                <div className="relative mt-1">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      onClick={handleSearch}
                    />
                  </div>
                  <input
                    type="text"
                    id="table-search"
                    onChange={handleSearch}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search Claims"
                  />
                </div>
                {can('add client claims') && (
                  <div
                    className="cursor-pointer ml-4 font-bold items-center flex btn"
                    onClick={addClaimClicked}
                  >
                    <span>Create Claim</span>
                  </div>
                )}
              </div>

              <table className="table-main">
                <thead>
                  <tr>
                    <th scope="col">Reference</th>
                    <th scope="col">Clinic</th>
                    <th scope="col">Service</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRecords.length > 0 ? (
                    currentRecords?.map((claim) => {
                      return (
                        <tr key={claim.id}>
                          <td>{claim.external_reference}</td>
                          <td>{claim.company_relationship?.name ?? ''}</td>
                          <td>{claim.service?.title ?? ''}</td>
                          <td>
                            <div
                              className={`status ${claim.status
                                .replace(/\s+/g, '-')
                                .toLowerCase()}`}
                            >
                              {claim.status}
                            </div>
                          </td>
                          <td>
                            {moment(claim.created_at).format('DD/MM/YYYY')}
                          </td>

                          <td>
                            <div className="flex justify-center space-x-2">
                              <span
                                title="View claim"
                                onClick={() => viewClaimClicked(claim)}
                                className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12  hover:bg-blue-500 cursor-pointer"
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </span>
                              {admin && claim.discharged != 1 && (
                                <span
                                  title="Complete/Discharge"
                                  onClick={() => dischargClaimClicked(claim)}
                                  className="flex justify-center items-center bg-red-400 rounded-md text-red-800 h-9 w-12  hover:bg-red-500 cursor-pointer"
                                >
                                  <FontAwesomeIcon icon={faUserXmark} />
                                </span>
                              )}
                              {admin && claim.discharged == 1 && (
                                <span
                                  title="Reopen Claim"
                                  onClick={() => reopenClaimClicked(claim)}
                                  className="flex justify-center items-center bg-yellow-400 rounded-md text-yellow-800 h-9 w-12  hover:bg-yellow-500 cursor-pointer"
                                >
                                  <FontAwesomeIcon icon={faFolderOpen} />
                                </span>
                              )}
                              {admin && (
                                <span
                                  title="Edit claim"
                                  onClick={() => editClaimClicked(claim)}
                                  className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12  hover:bg-blue-500 cursor-pointer"
                                >
                                  <FontAwesomeIcon icon={faPenToSquare} />
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <p className="no-records">No claims found</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                next={nextPage}
                prev={previousPage}
                first={indexOfFirstRecord}
                last={indexOfLastRecord}
                total={filteredRecords.length}
              />
            </div>
          )}
        </section>
      )}
    </div>
  )
}

export default ClientClaims
