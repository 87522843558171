/* eslint-disable */
import { useState, useEffect, useRef, Fragment } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import MainTitle from '../../components/MainTitle'
import { useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import ClaimSessions from '../../components/ClaimSessions'
import ClientNav from '../../components/ClientNav'
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption, Transition } from '@headlessui/react'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarPlus,
  faCheck,
  faChevronDown,
  faTimes,
  faTrash,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'

import ClaimAssessment from '../../components/ClaimAssessment'
import BookSession from '../../components/BookSession'
import TriageDetails from '../../components/TriageDetails'

const Claim = () => {
  const { clientId, claimId } = useParams()
  const axios = useAxiosPrivate()
  const [clientData, setClientData] = useState({
    name: '',
    reference: '',
  })
  const [claimData, setClaimData] = useState({
    reference: '',
    external_reference: '',
  })
  const { setShowLoader, auth, admin, createInfo, can } = useAuth()
  const [specialities, setSpecialities] = useState([])
  const [remainingSessions, setRemainingSessions] = useState(0)
  const [selectedSpeciality, setSelectedSpeciality] = useState({})
  const [bookSession, setBookSession] = useState(false)
  const [query, setQuery] = useState('')
  const [warningMessage, setWarningMessage] = useState(null);

  const getClaimById = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(`/clients/${clientId}/claims/${claimId}`)
      setClientData(response.data.result.client_relationship)
      setClaimData(response.data.result)
      getRemainingSessions(response.data.result)
    } catch (error) {}
    setShowLoader(false)
  }

  const getSpecialities = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/specialities/all`)
      if (response.data.result) {
        setSpecialities(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const getRemainingSessions = (claimData) => {
    let remaining = claimData.session_count - claimData.used_sessions.length
    setRemainingSessions(remaining)
  }

  useEffect(() => {
    getClaimById()
    getSpecialities()
  }, [claimId, clientId])

  useEffect(() => {
    if (clientData?.id) {
      let warning = null;
      if (clientData.insurer_history.length < 1) {
        warning = 'This client does not have any cover';
      } else if (
        clientData.insurer_history[0]?.policy_end_date &&
        moment(clientData.insurer_history[0].policy_end_date).isBefore(moment())
      ) {
        warning = 'This client\'s cover has expired.';
      }

      setWarningMessage(warning);
    }
  }, [clientData]);


  const filteredSpecialities =
    query === ''
      ? specialities
      : specialities.filter((speciality) =>
          speciality.title
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  const addToClaim = async () => {
    if (!selectedSpeciality.id) {
      createInfo('error', `No specific need selected`)
      return
    }

    setShowLoader(true)

    try {
      const response = await axios.get(
        `/claims/${claimData.id}/add-speciality/${selectedSpeciality.id}`
      )
      if (!response.data.error) {
        let temp = [...claimData.specialities]

        temp.push(selectedSpeciality)

        setClaimData({ ...claimData, specialities: temp })
        setSelectedSpeciality({})
        setShowLoader(false)
      }
    } catch (error) {
      setShowLoader(false)
    }
  }

  const deleteSpecialityClicked = async (speciality) => {
    setShowLoader(true)

    try {
      const response = await axios.get(
        `/claims/${claimData.id}/remove-speciality/${speciality.id}`
      )
      if (!response.data.error) {
        let temp = [...claimData.specialities]

        let index = temp.findIndex((item) => {
          return item.id == speciality.id
        })

        temp.splice(index, 1)

        setClaimData({ ...claimData, specialities: temp })
        setShowLoader(false)
      }
    } catch (error) {
      setShowLoader(false)
    }
  }

  return (
    <div>
      <MainTitle
        title={`Client: ${clientData.name} (${clientData.reference})`}
        client={clientData}
        backButton
        child={clientData.child}
        discharge
        waiting
      />

      <ClientNav active="claims" client={clientData} audit={claimData.id} type={'Claim'}/>

      {warningMessage && (
        <div className='mb-4'>
          <div className='bg-orange-200 text-orange-700 border shadow-md border-orange-700 font-medium px-4 py-2 rounded-lg'>
          <FontAwesomeIcon icon={faTriangleExclamation} className='pr-2 text-xl' /> {warningMessage}
          </div>
        </div>
      )}

      {claimData.id && (
        <section>
          <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
            <div className="flex justify-between">
              <div>
                <h2 className="font-bold text-lgmb-2">
                  {claimData.reference} ({claimData.external_reference}) -{' '}
                  {claimData.service.title}
                </h2>
                <p className="rounded-full text-blue-600 py-1 font-bold">
                 Preferred Contact Time: {clientData?.client_details?.contact_time}
                </p>
                <p className="rounded-full text-pink-600 py-1 mb-6 font-bold">
                  Remaining Sessions: {remainingSessions}
                </p>
              </div>
              {((claimData.assessment_flag === 0 &&
                claimData.had_assessment === false) ||
                (claimData.assessment_flag === 1 &&
                  claimData.had_assessment === true)) && (
                <>
                  {bookSession ? (
                    <div>
                      <button
                        className="btn red"
                        onClick={() => setBookSession(false)}
                      >
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div>
                      {claimData.discharged != 1 && (
                        <button
                          className="btn green"
                          onClick={() => setBookSession(true)}
                        >
                          <FontAwesomeIcon
                            icon={faCalendarPlus}
                            className="mr-2"
                          />
                          Book Session
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="mb-8">
              <h2 className="font-medium text-lg mb-4">Specific Needs</h2>
              {admin && (
                <div className="relative mt-1  z-10 flex">
                  <Combobox
                    value={selectedSpeciality}
                    onChange={setSelectedSpeciality}
                  >
                    <div className="max-w-xs relative w-full cursor-default overflow-hidden rounded-lg border-slate-200 border bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                      <ComboboxInput
                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                        displayValue={(speciality) => speciality.title}
                        onChange={(event) => setQuery(event.target.value)}
                      />
                      <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="text-gray-400"
                        />
                      </ComboboxButton>
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => setQuery('')}
                    >
                      <ComboboxOptions className="max-w-xs absolute mt-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredSpecialities.length === 0 && query !== '' ? (
                          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                            Nothing found.
                          </div>
                        ) : (
                          filteredSpecialities.map((speciality) => {
                            if (
                              !claimData.specialities.find((item) => {
                                return item.id == speciality.id
                              })
                            )
                              return (
                                <ComboboxOption
                                  key={speciality.id}
                                  className={({ focus }) =>
                                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                      focus
                                        ? 'bg-teal-600 text-white'
                                        : 'text-gray-900'
                                    }`
                                  }
                                  value={speciality}
                                >
                                  {({ selected, focus }) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected
                                            ? 'font-medium'
                                            : 'font-normal'
                                        }`}
                                      >
                                        {speciality.title}
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                            focus
                                              ? 'text-white'
                                              : 'text-teal-600'
                                          }`}
                                        >
                                          <FontAwesomeIcon icon={faCheck} />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </ComboboxOption>
                              )
                          })
                        )}
                      </ComboboxOptions>
                    </Transition>
                  </Combobox>
                  <button className="btn small ml-4" onClick={addToClaim}>
                    Add to claim
                  </button>
                </div>
              )}
              <ul className="flex w-full bg-slate-100 py-2 rounded mt-4">
                {claimData.specialities.length > 0 ? (
                  claimData.specialities?.map((speciality) => {
                    return (
                      <li key={speciality.id} className="flex">
                        <span className="pill small">{speciality.title}</span>
                        {can('remove claim specialities') ? (
                          <span
                            title="Remove Speciality"
                            className="pill-button small"
                            onClick={() => deleteSpecialityClicked(speciality)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </span>
                        ) : (
                          <span className="mr-4"></span>
                        )}
                      </li>
                    )
                  })
                ) : (
                  <p className="font-bold text-sm ml-4">
                    No Specific Needs added
                  </p>
                )}
              </ul>
            </div>
            <div>
              {can('access triage options') && (
                <>
                  <TriageDetails
                    claimData={claimData}
                    clientData={clientData}
                    referrableServices={
                      clientData.insurer_history[0]?.referrable_services ?? []
                    }
                  />
                </>
              )}
            </div>
            {(claimData.assessment_flag === 0 &&
              claimData.had_assessment === false) ||
            (claimData.assessment_flag === 1 &&
              claimData.had_assessment === true) ? (
              <>
                {bookSession ? (
                  <BookSession
                    claimData={claimData}
                    sessions={claimData.sessions}
                    setBookSession={setBookSession}
                    insurerHistory={clientData.insurer_history}
                  />
                ) : (
                  <ClaimSessions sessions={claimData.sessions} />
                )}
              </>
            ) : (
              <ClaimAssessment
                assessmentServices={claimData.service.assessment_services}
                assessmentData={claimData.assessment_options}
                claimData={claimData}
                clientId={clientData.id}
                confirmOptions={getClaimById}
                insurerHistory={clientData.insurer_history}
              />
            )}
          </section>
        </section>
      )}
    </div>
  )
}

export default Claim
